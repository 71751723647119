/* global appState, dataLayer, gtag, pushly */
import { MediaQuery as MQ } from '../shared/media-query';

window.hideSMVideoUnit = () => {
    if (window.blogherads && window.blogherads.floatingPlayerNoFloat) {
        window.blogherads.floatingPlayerNoFloat();
    }
};

window.showSMVideoUnit = () => {
    if (window.blogherads && window.blogherads.floatingPlayerNoFloatUnload) {
        window.blogherads.floatingPlayerNoFloatUnload();
    }
};

if (appState && appState.user && appState.user.member_uuid) {
    window.localStorage.setItem('member_uuid', appState.user.member_uuid);
} else {
    window.localStorage.removeItem('member_uuid');
}

//custom scrollspy for card & deck pages
if (document.querySelector('tarot-card-bar a[href="#upright"]') && MQ.isMobile()) {
    window.addEventListener('scroll', () => {
        const container = document.querySelector('tarot-card-bar');
        const scrollPos = document.querySelector('article a[href="#upright"]').getBoundingClientRect().bottom;

        if (
            (scrollPos < 145 && !container.classList.contains('show-anchors'))
            ||
            (scrollPos >= 145 && container.classList.contains('show-anchors'))
        ) {
            container.classList.toggle('show-anchors');
        }
    });
}

// eslint-disable-next-line complexity
setInterval(() => {
    const mobileStickyNav = document.querySelector('mobile-sticky-nav');
    const mobileHamburgerNav = document.querySelector('.toggle-menu');
    const footer = document.querySelector('footer');
    const adAdhesion = document.getElementById('pmc-atlasmg-adhesion-bar');

    if (mobileStickyNav && MQ.isMobile()) {
        if (window.scrollY >= 154) {
            if (mobileStickyNav.style.bottom != '0px') {
                mobileStickyNav.style.bottom = 0;
            }

            if (adAdhesion && adAdhesion.style.bottom != '84px') {
                adAdhesion.style.bottom = '84px';
            }
        } else if (
            window.scrollY < 154
            && mobileStickyNav.style.bottom != '-85px'
            && !mobileStickyNav.classList.contains('show-nav')
        ) {
            mobileStickyNav.style.bottom = '-85px';

            if (adAdhesion) {
                adAdhesion.style.bottom = 0;
            }
        }

        const observer = new MutationObserver((mutations_list) => {
            mutations_list.forEach((mutation) => {
                mutation.addedNodes.forEach((added_node) => {
                    if (added_node.id == 'pmc-atlasmg-adhesion-bar') {
                        if (mobileStickyNav.style.bottom != '-85px') {
                            added_node.style.bottom = '84px';
                        }
                        if (mobileHamburgerNav && mobileHamburgerNav.classList.contains('open')) {
                            added_node.style.zIndex = '0';
                        }
                    }

                    if (added_node.id == 'blogherads-branding-container') {
                        added_node.style.cssText = 'margin-bottom: 100px !important';

                        if (footer) {
                            footer.style.cssText = 'padding-bottom: 0 !important';
                        }
                    }

                    if (added_node.classList && added_node.classList.contains('fc-ccpa-root')) {
                        added_node.style.cssText = 'z-index: 0 !important';
                    }
                });
            });
        });

        observer.observe(document.body, { subtree: true, childList: true });
    }
}, 500);
// eslint-enable-next-line complexity

const togglePanel = (chapter, header) => {
    const navPanel = document.querySelector('nav-panel');
    // if panel is closed will open with skeleton loading animation
    // if already open this does nothing
    navPanel.classList.add('show-nav');

    requestAnimationFrame(() => {
        setTimeout(() => {
            const showNavSet = [
                navPanel,
                document.querySelector('mobile-sticky-nav'),
                document.body,
                document.querySelector('div.panel-overlay'),
            ];
            const panelLoader = navPanel.querySelector('panel-loader');
            const panelHeader = navPanel.querySelector('nav-panel p.fancy-title');

            if (chapter == 'x') {
                showNavSet.forEach(e => e.classList.remove('show-nav'));
                panelLoader.setAttribute('data-panel', '');
                panelHeader.innerHTML = '';
                window.showSMVideoUnit();
                return;
            }

            if (!navPanel.classList.contains('show-nav')) {
                const adAdhesion = document.getElementById('pmc-atlasmg-adhesion-bar');
                showNavSet.forEach(e => e.classList.add('show-nav'));
                panelLoader.setAttribute('data-panel', chapter);
                panelHeader.innerHTML = header;
                adAdhesion && (adAdhesion.style.zIndex = '50000');
                window.hideSMVideoUnit();
            } else {
                if (panelLoader.getAttribute('data-panel') == chapter) {
                    showNavSet.forEach(e => e.classList.remove('show-nav'));
                    panelLoader.setAttribute('data-panel', '');
                    panelHeader.innerHTML = '';
                    window.showSMVideoUnit();
                } else {
                    panelLoader.setAttribute('data-panel', chapter);
                    panelHeader.innerHTML = header;
                    window.hideSMVideoUnit();
                }
            }
        }, 0);
    });
};

window.onload = (() => {
    window.togglePanel = togglePanel;

    if (
        appState.user
        &&
        appState.user.auto_login
        &&
        !sessionStorage.getItem('pushly_autologin_updated')
    ) {
        // Add birthdate and sign to pushly only on first page load
        // after autologin (no effect or error if not subscribed to pushly)
        pushly('profile', {
            birth_date: appState.user.birth_date,
            sign: appState.user.default_sign,
        });
        sessionStorage.setItem('pushly_autologin_updated', true);
    }

    document.querySelectorAll('input[id^="action-bar-toggle"]').forEach(checkbox => {
        checkbox.addEventListener('change', () => {
            if (checkbox.checked) {
                document.querySelectorAll(`input:not([id="${checkbox.id}"])`).forEach(cb => {
                    cb.checked = false;
                });
            }
        });
    });
})();

(() => {
    const tarot_dropdown = document.querySelector('.tarot-dropdown');

    if (tarot_dropdown) {
        const tarot_dropdown_button = tarot_dropdown.querySelector(':scope > span');
        tarot_dropdown_button.addEventListener('click', () => {
            tarot_dropdown.classList.toggle('active');
        });
    }

    const dropdown_checkboxes = document.querySelectorAll('.dropdown-toggle');
    const dropdown_items = document.querySelectorAll('a.article-menu-item');

    dropdown_checkboxes.forEach((checkbox) => {
        checkbox.addEventListener('click', () => {
            document.querySelector(`label[for="${checkbox.id}"]`).classList.toggle('selected');

            if (checkbox.checked) {
                // on open, close all other dropdowns first
                dropdown_checkboxes.forEach((el) => {
                    if (el != checkbox) {
                        el.checked = false;
                        document.querySelector(`label[for="${el.id}"]`).classList.remove('selected');
                    }
                });
            }
        });
    });

    dropdown_items.forEach((el) => {
        el.addEventListener('click', () => {
            // on dropdown item click, close all dropdowns first
            dropdown_checkboxes.forEach((checkbox) => {
                checkbox.checked = false;
                document.querySelector(`label[for="${checkbox.id}"]`).classList.remove('selected');
            });
        });
    });

    const tocLabel = document.querySelector('label[for="table-contents-toggle"]');

    if (tocLabel) {
        const tocHeaders = Array.from(document.querySelectorAll('[data-toc-label]'));
        const stickyHeaders = document.querySelectorAll(
            'header.is-sticky, .article-menu, .table-contents-dropdown, .related-articles-dropdown'
        );
        let activeIndex = null;

        window.addEventListener('scroll', () => {
            let offset = 0;

            stickyHeaders.forEach((el) => {
                offset += el.offsetHeight;
            });

            // find the first table of contents section which is partially visible
            // below the sticky headers
            let index = tocHeaders.findIndex((el) => {
                return el.getBoundingClientRect().bottom > offset;
            });

            // when the index changes and is valid: remove any previous highlighted sections,
            // highlight the current section and update the table of contents label text
            if (index >= 0 && index < tocHeaders.length && index != activeIndex) {
                activeIndex = index;
                dropdown_items.forEach(link => link.classList.remove('reversed'));
                dropdown_items[activeIndex].classList.add('reversed');
                tocLabel.innerHTML = dropdown_items[activeIndex].innerHTML;
            }

            if (window.scrollY == 0 ) { // if user scrolls to the very top of page
                tocLabel.innerHTML = 'Table of Contents';
                activeIndex = null;
            }
        });
    }

    const article_menu = document.querySelector('.article-menu');
    const stickyHeader = document.querySelector('header.is-sticky');

    if (article_menu && stickyHeader) {
        // if forecast page has both daily nav and article menu
        // adjust the article menu to stick below daily nav
        article_menu.style.top = getComputedStyle(stickyHeader).height;
    }

    const daily_nav = document.querySelector('daily-nav');
    const daily_button = document.querySelector('.daily-nav-button');

    if (daily_nav && daily_button) {
        daily_button.addEventListener('click', () => {
            daily_nav.classList.toggle('is-open');
        });
    }
})();

window.carouselRotate = (e) => {
    const parent = e.target.parentElement;
    const first = parent.querySelector('a:first-of-type');
    if (first) {
        first.classList.add('move');
        setTimeout(() => {
            parent.appendChild(first);
            first.classList.remove('move');
        }, 501);
    }
};

window.dataLayer = window.dataLayer || [];
window.gtag = function() { dataLayer.push(arguments); };

gtag('js', new Date());

gtag('config', appState.ga4_site_id, {
    cookie_domain: 'tarot.com',
    user_id: window.localStorage.getItem('member_uuid') || undefined,
    linker: {
        domains: ['tarot.com', 'dailyhoroscope.com', 'numerology.com', 'twittascope.com', 'zappallas-usa.com'],
    },
});

gtag('event', 'Enabled', {
    event_category: 'Adblock',
    event_label: document.getElementById('KFnpNtDBXzAZ') ? 'No' : 'Yes',
    non_interactive: true,
});

if (appState.ga_order_data && appState.ga_order_data.transaction) {
    if (appState.ga_order_data.click_event) {
        const upc = appState.ga_order_data.products &&
            appState.ga_order_data.products[0] &&
            appState.ga_order_data.products[0].id;

        gtag('event', 'Click', {
            event_info: `Purchase_${upc}:${appState.ga_order_data.click_event}`,
            page_referrer: document.referrer,
            page_location: window.location.href,
        });
    }

    const transaction = appState.ga_order_data.transaction;
    transaction.items = appState.ga_order_data.products || [];

    gtag('event', 'purchase', transaction);
}
